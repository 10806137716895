<!--  -->
<template>
  <div class='lrModule' :style="{'flex-direction':flexDrect}">
    <div>
       <Title :title="title" :lineW="lineW" :titleW="titleW" :mgTop="mgTop"></Title>
       <slot></slot>
    </div>
    <div :class="className">
      <img :src="moduleImg" :alt="imgAlt" :title="imgTitle">
    </div>
  </div>
</template>

<script>
import Title from './Title.vue'
export default {
  name:'LrMoudule',
  components: {
    Title
  },
  props:{String,
    moduleImg:String,
    title:String,
    lineW:String,
    className:String,
    mgTop:String,
    flexDrect:{
      type:String,
      default:'row'
    },
    imgTitle:{
      type:String,
      default:''
    },
    imgAlt:{
      type:String,
      default:''
    }
  },
  data() {
     return {
        titleW:'416px'
     };
  },
  computed: {},
  watch: {},
  created() {
 
  },
  mounted() {

  },
  //方法集合
  methods: {

  },
  updated() {},
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .lrModule{
    width:1200px;
    margin:100px auto 0;  
    display: flex;
    justify-content:space-around;
    img{
      display:block;
    }
  }

</style>