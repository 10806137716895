//找企鸟
export const zqn = {
  banner: {
    bannerImg: require('@img/zqn/banner1.png'),
  },
  modular2: {
    title: "TOB销售线索平台，解决企业获客难题",
    list: [{
        icon: require("@img/zqn/TOB-1.png"),
        alt: '22',
        title: '强大的搜索引擎',
        p: '多种条件组合，查询获取精准客户'
      },
      {
        icon: require("@img/zqn/TOB-1.png"),
        alt: '22',
        title: '多维度触达客户',
        p: '多重维度筛选客户，多重联系方式触达客户'
      },
      {
        icon: require("@img/zqn/TOB-1.png"),
        alt: '22',
        title: '高质量线索池',
        p: '覆盖全网数据，找到专属您的客户'
      },
    ]
  },
  modular3: {
    title: "解决企业四大困局",
    list: [{
        icon: require("@img/zqn/icon-1.png"),
        alt: '11',
        title: '高质量线索少，获客成本高',
        p: '市场规模有限，竞争对手增加，高质量线索难寻，获客成本逐步提高。'
      },
      {
        icon: require("@img/zqn/icon-2.png"),
        alt: '11',
        title: '获客效率低',
        p: '销售每天工作10小时，要花3个小时在网上、线下找资源，费时费力。'
      },
      {
        icon: require("@img/zqn/icon-3.png"),
        alt: '11',
        title: '资源不精准，成交难',
        p: '市面主流获客渠道，获客成本相对较低，但线索质量低下，信息少，难成交。'
      },
      {
        icon: require("@img/zqn/icon-4.png"),
        alt: '11',
        title: '客户来源不稳定',
        p: '客户来自不同渠道，时间不定，数量不定，质量不定。'
      },
    ]
  },
  modular4: {
    title: '选择我们的四大优势',
    list: [{
        img: require("@img/zqn/tu1.png"),
        alt: '33',
        h4: '覆盖全网企业信息商机实时更新',
        p: '上亿家企业客户资源线索及工商信息实时更新，每天商机不断'
      },
      {
        img: require("@img/zqn/tu2.png"),
        alt: '33',
        h4: '多维度标签丰富快速精准配',
        p: '上万个标签，精准划分企业类型，多维度触达，筛选高质量精准客户'
      },
      {
        img: require("@img/zqn/tu3.png"),
        alt: '33',
        h4: '行业覆盖全领域',
        p: '多行业维度企业资料收集，满足您的维度需求快速开启拓客'
      },
      {
        img: "@img/zqn/tu4.png",
        alt: '33',
        h4: '无缝对接CRM库',
        p: '获取的潜在客户可批量上传，快速查询，一键补全慧营销中的客户信息，加速成交'
      },
    ]
  },
  modular5: {
    title: '低成本获取高意向客户，提升效能加速成交',
    list: [{
        img: require("@img/zqn/img1.png"),
        alt: '',
        h4: '获取上亿条线索',
        // p: '',
        list: [
          '<span>查企业：</span>支持多种查询条件，让销售人员快速精准找到企业。企业工商信息、联系方式、知识产权、经营状态等企业信息随时查询，实时更新。',
          '<span>找线索：</span>提供200多个筛选条件，可通过关键词进行高精度筛选，智能精准定位目标客户。',
          '<span>批量查企业：</span>支持企业名录导入，系统自动匹配信息，快速补全企业信息，补全后支持导出存档。'
        ]
      },
      {
        img: require("@img/zqn/img2.png"),
        alt: '44',
        h4: '多维度筛选，精准触达',
        list: [
          '<span></span>通过行业模板、多维度筛选条件、地图检索等，获取精准客户，快速精准触达高质量目标客户群。',
          '<span>行业模板：</span>提供几十个行业模板，点击使用即可完成筛选条件的组合搭配。',
          '<span>地图检索：</span>根据地理位置精准探查，获取公司信息。'
        ]
      },
      {
        img: require("@img/zqn/img3.png"),
        alt: '55',
        h4: '管理线索',
        list: [
          '<span>线索领取：</span>管理解锁领取线索，可重新分配，领取至我的资源或待分配资源。',
          '<span>找线索：</span>提供200多个筛选条件，可通过关键词进行高精度筛选，智能精准定位目标客户。',
          '<span>及时跟进线索：</span>跟进中的线索、跟进中的联系人、公海线索清晰分类，销售跟进更及时。'
        ]
      },
      {
        img: require("@img/zqn/img4.png"),
        alt: '44',
        h4: '统计分析',
        list: [
          '<span>线索统计：</span>基于线索的使用量和效果，可视化地生成数据报表，帮助企业快速搭建完善的薪酬激励体系，提升人员效能，及时地发现问题，解决问题，及时调整经营管理方式，制定销售策略。',
        ]
      },
    ]
  }
}
//外呼中心
export const callCenter = {
  banner: {
    bannerImg: require('@images/callCenter/banner.png'),
    h3: '慧营销呼叫中心',
    ul: ['稳定、高效的智能电销系统', '线路资源稳定，呼叫+CRM管理一体化']
  },
  callCmod2: {
    title: '电话营销之痛',
    imgsrc: require('@images/callCenter/tu1.png'),
  },
  callCmod3: {
    title: '选择慧营销呼叫中心提升营销效率及销售业绩',
    text: '电话销售 客服服务 业务咨询 语音通知 企业服务 用户推广',
    ul: [{
        title: '智能呼叫中心',
        p: '灵活部署，4G全网通与SIP固话，提高外呼效率，降低封号风险，通话清晰稳定。号码状态检测，筛选出异常号码，提高坐席拨打效率。',
        icon: require('@images/callCenter/icon1.png'),
        taglist: ['队列管理', '来电弹屏', '多级IVR语音', '多层级客户流转']
      },
      {
        title: '高效智能营销',
        p: '数据批量导入，自定义规则分配。线路稳定，自动连拨，高效触达客户沟通。通话记录录音，时刻把握商机，满足营销需求。',
        taglist: ['数据权限设置', '自动分配', '跟进记录', '批量呼出', '任务回访计划'],
        icon: require('@images/callCenter/icon1.png')
      },
      {
        title: '一站式管理',
        p: '打通CRM系统实时呼叫动态监控，多维度数据分析。管理人员可以对在线坐席实时监控，查看数据报表统计，对通话时长考核管理。',
        taglist: ['坐席监控', '工作量统计', '任务数据统计', '通话记录管理'],
        icon: require('@images/callCenter/icon1.png')
      }
    ]
  },
  callCmod4: {
    title: '四大核心优势',
    ul: [{
        title: '操作简单拨打方便',
        list: [
          '开放的API接口，可接入自有线路及其他各种线路自由切换',
          '操作简单，在电脑上登录即可拨号'
        ],
        icon: require('@images/callCenter/icon4.png')
      },
      {
        title: '提供线路资源选择多',
        list: [
          '有AXB、回拨、SIP等解决高频封号问题',
          '号码资源多，随时可办理'
        ],
        icon: require('@images/callCenter/icon5.png')

      },
      {
        title: '提高呼入呼出效率',
        list: [
          '一键拨号号码隐藏',
          '来电弹屏自动连拨',
          'IVR语音导航',
          '呼叫任务管理'
        ],
        icon: require('@images/callCenter/icon6.png')
      }, {
        title: '管理与数据分析',
        list: [
          '对接CRM系统，所有通话记录和录音可存储',
          '通话报表、在线报表、工单等多报表统计管理'
        ],
        icon: require('@images/callCenter/icon7.png')
      }
    ]
  },
  callCmod5: {
    title: '为什么选择我们十年系统深耕值得信赖',
    ul: [{
        title: '快速部署成本低',
        p: '快速部署，无需服务器和运维人员，轻松上手',
        icon: require('@images/callCenter/icon8.png')
      },
      {
        title: '自主研发成熟系统',
        p: '上百人研发团队，软硬件自主研发，多项国家发明专利和知识产权成果',
        icon: require('@images/callCenter/icon9.png')
      }, {
        title: '三大运营商合作',
        p: '专业的三大运营商合作，通信稳定，音质清晰，降低封号风险',
        icon: require('@images/callCenter/icon10.png')
      }, {
        title: '完善的用户服务体系',
        p: '7*12小时顾问式客服一对一服务，提供技术支持、远程协作、问题处理等服务',
        icon: require('@images/callCenter/icon11.png')
      },
    ]

  },
  callCmod6: {
    title: '强大的电话呼叫中心',
    imgsrc: require('@images/callCenter/tu3.png')
  },
  callCmod7: {
    title: '呼叫中心能为你做什么',
    list: [{
      img: require('@images/callCenter/ptu-1.png'),
      alt: '',
      h4: '快速筛选客户线索',
      p: '为中小企业提供高效的电话营销服务,<Br />告别低效的销售方式，通过呼叫中心，可以拨打电话，快速<br />筛选客户线索，挖掘有价值客户并跟进'
    }, {
      img: require('@images/callCenter/ptu-2.png'),
      alt: '',
      h4: '高效管理销售工作',
      p: '管理者通过呼叫中心，方便快捷管理每个销售工作，查看<Br />工作情况，听取录音，不断优化销售工作'
    }, {
      img: require('@images/callCenter/ptu-3.png'),
      alt: '',
      h4: '提高效率和客户满意度',
      p: '标准化的服务流程，降低了企业对员工培训的成本，提升了员工维护客户的质量，统一的客户回访话术，提升了客户对企业满意度和服务口碑'
    }, {
      img: require('@images/callCenter/ptu-4.png'),
      alt: '',
      h4: '提升管理质量和转化',
      p: '实时了解运营客服人员工作安排和工作内容，通过指导工作<br />进而优化运营服务人员的工作效率，提高客服人员工作质量<br />和成功转化。'
    }, ]
  },
  callCmod8: {
    title: '为更多行业提供专属解决方案',
  }
}
// 电销卡 
export const dxk = {
  banner: {
    bannerImg: require('@images/dxk/banner.png'),
    h3: '企蜂电销卡',
    ul: ['白名单、资费低、高频拨打', '全国归属地号码，即开即用']
  },
  modular1: {
    title: '企业电销痛点',
    ul: [{
        title: '容易被封号',
        p: '自然号码一打电话就被封，无法开展业务'
      },
      {
        title: '离职率高',
        p: '人员流失率高，业务员换了一批又一批'
      },
      {
        title: '客户记录分散',
        p: '呼出电话后经常会有信息记录不及时或无记录，造成后续员工跟进困难'
      },
      {
        title: '拨打接通率低',
        p: '客户咨询回访量大，无效拨打多，接通率低'
      }
    ]

  },
  modular2: {
    title: '我们电销卡的优势<Br />高频防封归属地可选',
    list: [{
      h4: '全国归属地',
      img: require('@images/dxk/icon1.png'),
      p: '全国归属地可用，电销卡销售专用'
    }, {
      h4: '稳定防封',
      img: require('@images/dxk/icon2.png'),
      p: '审核后即开即用，白名单稳定性高'
    }, {
      h4: '资费低',
      img: require('@images/dxk/icon3.png'),
      p: '提供高性价比，资费低至0.1左右'
    }, {
      h4: '种类多样',
      img: require('@images/dxk/icon4.png'),
      p: '通信套餐多，适用不同行业业务场景'
    }]
  },
  modular3: {
    title: '企蜂销售专属电销卡+呼叫中心<br />提高工作效率，精确找到客户',
    list: [{
        img: require('@images/dxk/tu2.png'),
        title: '全国归属地电销卡'
      },
      {
        img: require('@images/dxk/tu3.png'),
        title: '白名单专用电销卡'
      },
      {
        img: require('@images/dxk/tu4.png'),
        title: '大语音卡'
      },
      {
        img: require('@images/dxk/tu5.png'),
        title: '高频抗封电销卡'
      },
    ]
  },
  modular4: {
    title: '为什么选择我们',
    list: [{
        p: '十余年通信解决方案<br />三大运营商合作'
      },
      {
        p: '公司规模大，资质证书齐全，增<br />值电信业务经营许可证'
      },
      {
        p: '国家发明专利技术成果及<br />知识产权'
      },
      {
        p: '上百人的技术团队，研发实力强'
      }
    ]
  },
  modular5: {
    title: '电销卡的解决方案',
    list: [{
        h4: '配合高效外呼系统',
        p: '来电弹屏、屏幕取号、一键呼叫，呼叫监控、坐席监控，接通统计',
      },
      {
        h4: '主叫变被叫',
        p: '线路选择多，主叫变被叫，上百通电话也能降低被封号风险',
      },
      {
        h4: '支持电脑、手机',
        p: '为企业提供基于固话、手机的软硬一体化的通信解决方案。支持移动、联通、电信三大运营商',
      },
    ]
  },
  modular6: {
    title: '电销卡适用行业',
    img: require('@images/dxk/tu6.png'),
    imgtitle: '',
    alt: ''
  }
}
//crm
export const crm = {
  banner: {
    bannerImg: require('@images/crm/banner.png'),
    h3: '慧营销CRM系统',
    ul: ['获取优质线索，挖掘更多商机', '从线索至成单全流程把控', '高效客户管理及销售管理', '加速转化，提升业绩']
  },
  modular1: {
    title: "亟待解决的企业营销服务客户管理难题",
    imgsrc: require('@images/crm/tu.png')
  },
  modular2: {
    title: "为什么选择慧营销CRM",
    p: '从客户线索到成单，全生命周期把控',
    list: [{
        h4: '全渠道引流获客',
        imgsrc: require('@images/crm/icon1.png')
      },
      {
        h4: '精细化客户管理',
        imgsrc: require('@images/crm/icon2.png')
      },
      {
        imgsrc: require('@images/crm/icon3.png'),
        h4: '透明化团队管理',
      },
      {
        imgsrc: require('@images/crm/icon4.png'),
        h4: '可视化数据分析',
      }
    ]
  },
  modular3: {
    title: '慧营销CRM主要功能',
    list: [{
        h4: '多渠道引流获客',
        imgsrc: require('@images/crm/tu1.png'),
        plist: [{
            p: '打通电话、Web、H5、App、企业微信、QQ、找企鸟、名片、市场推广多个渠道引流获客，统一线索管理。'
          },
          {
            p: '配合高效CRM无缝对接电销，通过呼叫中心一键拨号、通话录音、屏幕取号、来电弹屏等高效触达客户。'
          },
          {
            p: '根据市场活动ROI的数据分析，持续不断的优化营销渠道，获得更多数量的线索。'
          }
        ]
      },
      {
        h4: '智能高效管理客户',
        imgsrc: require('@images/crm/tu2.png'),
        plist: [{
            span: '资源管理：',
            p: '可根据销售管理习惯将资源进行自定义分类导入，入库提醒，未联系资源及时回收流转。'
          },
          {
            span: '客户跟进：',
            p: '对资源快速沟通处理分流，一键拨号，一键添加好友，自动连拨沟通后自定义标签。'
          },
          {
            span: '客户管理：',
            p: '接查看客户卡片，跟进、星标、合同创建，全面了解客户详细情况。'
          }
        ]
      }, {
        h4: '团队透明化管理，科学决策',
        imgsrc: require('@images/crm/tu3.png'),
        plist: [{
            p: '实现追踪销售过程及客户开发进度的管理，销售可了解与他人的业绩差距；多维度数据分析，管理层可实时了解整个团队情况，调整策略。 '
          },
          {
            p: '营销管理者科学制定PDCA销售计划，业绩目标与销售计划匹配执行，随时随地穿透销售进度。'
          },
          {
            p: '从提供资源对接、跟进、库容、回收、签约多节点把控，对商机、客户资源、成交订单、市场各维度数据统计及分析，全业务流程数据追踪，助力决策。'
          }
        ]
      }, {
        h4: '社交营销方式变革',
        imgsrc: require('@images/crm/tu4.png'),
        plist: [{
            p: ' 企业微信好友数据统计、自动推送欢迎语、素材库与话术库等多种触达工具，达到引流、促活、运营管理的目的。 '
          },
          {
            p: '基于企微直播，打造企业线上线下一体化营销闭环，客户资源沉淀在CRM系统。'
          },
          {
            p: '跟进记录一键与慧营销同步，同时做到客户数据沉淀，有效降低企业客户资产流失风险。'
          }
        ]
      }, {
        h4: '可视化数据报表',
        imgsrc: require('@images/crm/tu5.png'),
        plist: [{
            p: '电脑和慧营销手机APP协同办公，随时查看销售工作数据，如客户开发数量、通话时长，管理报表等等，员工工作随时掌握。 '
          },
          {
            p: '销售团队透明管理，打造高效营销模式，根据销售目标或者业务流程形成可视化数据报表，为企业提供针对性预测和建议。'
          }
        ]
      },
    ]
  },
  modular4: {
    title: '慧营销CRM不辜负每个客户信赖',
    list: [{
        h3: '成熟的产品',
        p: '十余年深耕CRM系统深入了解客户管理需求 ',
        imgsrc: require('@images/crm/beijing01.png')
      },
      {
        h3: '高性价比',
        p: '稳定的价格体系，成本低，功能全面',
        imgsrc: require('@images/crm/beijing02.png')
      },
      {
        h3: '快速部署',
        p: 'SaaS快速部署，无需服务器和运维人员轻松上手',
        imgsrc: require('@images/crm/beijing03.png')
      },
      {
        h3: '优质服务',
        p: '用户7x12h服务紧急问题快速处理',
        imgsrc: require('@images/crm/beijing04.png')
      }
    ]
  },
  modular5: {
    title: '为10W+客户提供专业解决方案',
    p: '覆盖互联网、金融教育、电商、汽车行业、企业服务、医疗美容、房地产等众多行业领域，产品应用普及率广泛',
    imgsrc: require('@images/crm/tu6.png')
  },
}
// 电销线路
export const dxxl = {
  banner: {
    bannerImg: require('@images/dxxl/banner.png'),
    h3: '电销线路',
    ul: ['提供稳定企业通信线路，降低封号风险', '全国套餐可选，配合企业呼叫中心', '提高通话质量和接通率，提升业绩', ]
  },
  modular1: {
    title: '企业还在为通信困扰吗？',
    list: [{
      h4: '关注封号问题',
      p: '电销呼出频繁容易被封号，影响业绩，电销系统是需要搭配电销线路进行使用。',
      imgsrc: require('@images/dxxl/tu1.png'),
    }, {
      h4: '没有稳定通信',
      p: '打了几通电话就被限制，没有稳定的线路保证工作正常开展，线路经常不能使用。',
      imgsrc: require('@images/dxxl/tu2.png'),
    }]
  },
  modular2: {
    title: '提高通话质量和接通率<br />稳定高效呼叫解决方案',
    list: [{
      h4: '有AXB、回拨、SIP多线路模式提高接通率',
      p: '三大运营商合作，各种线路可选，价格合适，接听率高，支持不同行业客户',
      img: require('@images/dxxl/quan.png'),
    }, {
      h4: '通信线路稳定，电销效率翻倍',
      p: '通信稳定，可长期使用优质线路，有备用号码，呼叫次数、效率比普通电销翻倍',
      img: require('@images/dxxl/fang.png'),
    }, {
      h4: '线路资源丰富，提交资料后能及时使用',
      p: '号码资源丰富，客户提供资料提交后能迅速使用，开通快效率高',
      img: require('@images/dxxl/wen.png'),
    }, {
      h4: '降低封号风险，有效防护',
      p: '对呼出频度、呼叫时间段做限制，对呼出对象筛查做过滤，有效防封',
      img: require('@images/dxxl/kuai.png'),
    }]
  },
  modular3: {
    title: '配合慧营销呼叫中心系统<br />提高销售工作效率及业绩',
    list: [{
      p: '呼叫系统可接入任何线路，包括自有线路及其他各种线路自由切换',
      img: require('@images/dxxl/icon1.png'),
    }, {
      p: '系统功能适用各行业，多种线路类型支持多类型客户需求',
      img: require('@images/dxxl/icon2.png'),
    }, {
      p: '系统功能齐全呼叫中心搭配CRM客户管理系统，一站式服务，即开即用',
      img: require('@images/dxxl/icon3.png'),
    }, {
      p: '软硬件相结合,有呼叫中心+自主研发T5双重保障，线路稳定，高效客户管理',
      img: require('@images/dxxl/icon4.png'),
    }, {
      p: '线路+系统成本低，性价比高，提供专业的不同解决方案，适配度高',
      img: require('@images/dxxl/icon5.png'),
    }, {
      p: '一键拨号，录音实时抓取，有通话录音提供质检。有效接通量，平均时长统计',
      img: require('@images/dxxl/icon6.png'),
    }]
  },
  modular4: {
    title: '为什么选择我们，十年系统深耕值得信赖',
    list: [{
      h4: '快速部署成本低',
      p: '快速部署，无需服务器和运维人员，轻松上手',
      img: require('@images/dxxl/icon8.png'),
    }, {
      h4: '自主研发成熟系统',
      p: '上百人研发团队，软硬件自主研发，多项国家发明专利和知识产权成果',
      img: require('@images/dxxl/icon9.png'),
    }, {
      h4: '三大运营商合作',
      p: '专业的三大运营商合作，通信稳定，音质清晰，降低封号风险',
      img: require('@images/dxxl/icon10.png'),
    }, {
      h4: '完善的用户服务体系',
      p: '7*12小时顾问式客服一对一服务，提供技术支持、远程协作、问题处理等服务',
      img: require('@images/dxxl/icon11.png'),
    }]
  }
}
//企蜂名片
export const qfmp = {
  banner: {
    bannerImg: require('@images/qfmp/banner.png'),
    h3: '企蜂名片',
    ul: ['解锁私域流量', '品牌宣传，挖掘商机，智能获客，管理成交', '社交流量沉淀为客户，提升销售效率及业绩', ]
  },
  modular1: {
    title: '每个销售都应该成为企业对外宣传节点',
    imgsrc: require('@images/qfmp/bg.png'),
  },
  modular2: {
    title: "内容信息展示宣传<br />挖掘社交渠道的客户资源",
    bgimg: require('@images/qfmp/tu.png'),
    list: [{
        img: require('@images/qfmp/icon1.png'),
        h4: '企业宣传',
        p: '配置专属企业logo小程序，企业员工资料全面展示'
      },
      {
        img: require('@images/qfmp/icon2.png'),
        h4: '引流获客',
        p: '热点资讯一键分享，内容传播精准获客'
      },
      {
        img: require('@images/qfmp/icon3.png'),
        h4: '营销服务',
        p: '统一展示企业信息产品，动态及推文、客户案例实时更新'
      },
      {
        img: require('@images/qfmp/icon4.png'),
        h4: '跟进成交',
        p: '自动分析获客数据，帮助分析洞察名片获客及转化效果'
      },
    ]
  },
  modular3: {
    title: '企蜂名片能做什么',
    list: [{
        h4: '1、打造企业专属名片',
        imgsrc: require('@images/qfmp/tu1.png'),
        plist: [{
            span: '专属logo设计展示企业形象',
            p: '自定义配置带有logo的专属企业名片，可在小程序中直接搜索企业单位名片，直达客户需求，全方位展示企业形象。'
          },
          {
            span: '不同部门选择名片展示页',
            p: '可根据不同部门定制符合职业性质的特色名片，营销名片、服务名片、人事名片等适合不同业务部门及业务场景。'
          },
          {
            span: '专属模板选择适合不同场景',
            p: '多种名片模板满足不同场景需求，搭建出个性化的名片小程序风格和首页样式。'
          },
          {
            span: '个人风采展示增加客户粘性',
            p: '可文字及语音多种方式编辑个人信息，多维度展示名片上的个人风采。'
          }
        ]
      },
      {
        h4: '2、私域引流客户轨迹捕捉',
        imgsrc: require('@images/qfmp/tu2.png'),
        plist: [{
            span: '名片快速引流',
            p: '个人名片可一键生成小程序二维码海报，线上线下随时分享名片至好友及微信群。'
          },
          {
            span: '商机信息推送提醒',
            p: '有了新的访客咨询和浏览信息会及时通知销售，及时掌握线索信息。'
          },
          {
            span: '轨迹实时捕捉',
            p: '销售能够主动在企蜂名片中跟踪客户动态，轻松把控客户轨迹，帮助销售洞察客户需求。'
          }
        ]
      }, {
        h4: '3、客户统一管理加速转化',
        imgsrc: require('@images/qfmp/tu3.png'),
        plist: [{
            span: '线索入库',
            p: '名片线索入库，访客一键转线索，自动同步客户资料与跟进信息。 '
          },
          {
            span: '访客线索立即跟进沟通',
            p: '小程序沟通，不加好友快速对话，便捷向客户推送资料、交换电话号码。'
          },
          {
            span: '客户统一管理分析',
            p: '系统自动分析获客数据，自动创建客户电子名片，销售可快速保存客户名片信息进行统一管理。'
          }
        ]
      }, {
        h4: '4、企业资讯实时更新赋能营销',
        imgsrc: require('@images/qfmp/tu4.png'),
        plist: [{
            span: '公司品牌宣传窗口',
            p: ' 统一展示企业信息及产品，公司照片、企业画册等多维度信息展示，传递企业价值。 '
          },
          {
            span: '视频营销轻松引流',
            p: '宣传视频及公众号视频同步更新，可一键分享至微信好友及朋友圈。'
          },
          {
            span: '动态信息实时更新',
            p: '每天更新动态及推文，客户案例实时更新，提升信任感，助力成单。'
          }
        ]
      }, {
        h4: '5、无缝互通企蜂云产品轻松成交',
        imgsrc: require('@images/qfmp/tu5.png'),
        plist: [{
            span: '无缝互通慧营销',
            p: '名片与慧营销无缝互通，低成本的SaaS名片，各种线索数据轻松查看跟进。 '
          },
          {
            span: '一键跳转至商城',
            p: '跳转到企蜂商城的入口，作为商品多样化的微商城，满足企业不同需求。'
          }, {
            span: '轻松形成销售闭环',
            p: '客户在小程序内完成订单转化，很好地形成了集产品呈现、产品咨询、产品购买于一体的销售闭环。'
          },
        ]
      },
    ]
  }
}
//企蜂名片
export const wxyx = {
  banner: {
    bannerImg: require('@images/wxyx/banner.png'),
    h3: '企蜂微信营销',
    ul: ['企业微信结合CRM系统，提供私域运营解决方案', 'SCRM系统实现客户引流、客户运营、客户管理转化一键闭环', ]
  },
  modular1: {
    title: '倾听企业的心声',
    bgimg: require('@images/wxyx/banner.png'),
    list: [{
        h3: '如何快速抓取盘活客户？',
        p: '企业通过广告投放或市场推广来获取客户资源，往往效果差强人意，获客成本高。意向客户在微信端口不知道如何去营销进行盘活。'
      },
      {
        h3: '怎样追踪销售过程？',
        p: '销售用社交跟进，客户详情无法全面掌握。社交营销跟进无法与客户管理系统实时同步，影响销售管理决策。销售跳单飞单状况频出，管理无头绪。'
      },
      {
        h3: '客户如何全面精细化的管理？',
        p: '企业微信中的客户跟进情况不能直接对接到客户管理系统进行协同办公。微信好友的情况不能实时把控而导致丢失成单机会。'
      }
    ]
  },
  modular2: {
    title: '企蜂云SCRM实现快速获客<br />客户全生命周期管理',
    imgsrc: require('@images/wxyx/tu.png'),
  },
  modular3: {
    title: '企蜂云SCRM系统',
    list: [{
        h4: '01  多渠道活码引流获客',
        imgsrc: require('@images/wxyx/tu1.png'),
        plist: [{
            span: '渠道活码',
            p: '直接扫码添加员工快速引流客户，进行客户留存及转化'
          },
          {
            span: '群活码',
            p: '引流客户，根据客户画像进行建群营销，精细化进行群管理'
          },
          {
            span: '自动添加好友',
            p: '客户扫描员工的渠道活码后，将自动添加员工企微好友'
          }
        ]
      },
      {
        h4: '02  多种营销玩法促活',
        imgsrc: require('@images/wxyx/tu2.png'),
        plist: [{
            p: '<em>企微直播</em>精准预约客户，并实时互动交流，深挖客户需求'
          },
          {
            p: '<em>群发群，群发好友，群发朋友圈，</em>多维度盘活存量客户，<em>好友sop，群sop，</em>标准化运营和管理好友和群'
          },
          {
            p: '企微中嵌入<em>素材库与话术库，使销售更具专业性</em>，同时素材库拥有图片、海报、文章、网页、画册、文件、表单等，丰富的素材助攻营销'
          }
        ]
      }, {
        h4: '03  客户高效跟进管理',
        imgsrc: require('@images/wxyx/tu3.png'),
        plist: [{
            span: '客户跟进记录',
            p: '企微侧边栏与客户沟通时，可实时新增跟进记录及商机，也可对该客户在慧营销内管理 '
          },
          {
            span: '企微好友管理',
            p: '在慧营销中即可对企微好友进行管理，如添加客户，添加联系人，查看客户卡片，打标签等使客户管理清晰化'
          },
          {
            span: '客户统一管理',
            p: '根据客户的聊天内容、行为、素材访问轨迹等，通过侧边栏对用户信息进行补充说明'
          }
        ]
      }, {
        h4: '04  员工行为全流程管理',
        imgsrc: require('@images/wxyx/tu4.png'),
        plist: [{
            span: '离职继承',
            p: ' 在职员工的客户可直接分配给其他员工，离职员工客户可一键分配给其他员工 '
          },
          {
            span: '会话存档',
            p: '记录员工通话内容、沟通记录等，支持管理员随时查看，销售过程实时监控'
          },
          {
            span: '社交工作分析',
            p: '通过好友总数，新增好友数，流失好友数等维度详细分析，充分了解销售的工作状态'
          }
        ]
      }
    ]
  }
}
// 慧营家
export const hyj = {
  banner:{
    bannerImg: require('@images/hyj/banner.png'),
    title: '慧营家',
    list: ['一站式金融助贷运营服务系统', '围绕五大环节<span>“获客—筛客—跟进预约—到店谈单—进件放款"</span>', '为助贷企业提供专业的数字化解决方案'],
    class: "bannerStyleHyj"
  },
  md1:{
    titleClass:'',
     title: '慧营家为助贷企业解决以下难题',
     list:['成本高，电销、地推、线上等模式成本逐年增加',
     '企业流失率高，员工离职带走客户，无法全面管控员工',
     '贷款客户资料和业务表格多且混乱，容易丢失或被带走',
     '贷款业务流程管理不规范，管理无法掌握每个人进度和谈单过程，容易被投诉',
     '寻找资源时高频呼叫易封卡、封号，通话记录无法存储',
     '销售易飞单，客户分配跟进不及时没有侧重，整体签单率低',
    '缺乏对客户科学评估，贷款客户信息易泄露，资料安全无法保障']
  },
  md2:{
    title:'提供从营销获客，呼叫管理，客户管理，数据管理等CRM解决方案支持移动端+PC端，帮助助贷企业高效运营服务',
    list:[
      {
        tabTitle:'智能获客',
        icon:require('@images/hyj/hyjmd2-icon1.png'),
        img:require('@images/hyj/hyjmd2-img1.png'),
        tabConList:'通过多种渠道获取客户信息，例如电销、AI语音机器人、社交营销、找企鸟、销售自己资源等营销渠道的拓宽。进入系统后集中每条线索，线索相关信息都统一高效管理。',
        bg:'#f5f8ff'
      },
      {
        icon:require('@images/hyj/hyjmd2-icon2.png'),
        img:require('@images/hyj/hyjmd2-img2.png'),
        bg:'#f5f8ff',
        tabTitle:'呼叫管理',
        tabConList:'移动化的呼叫中心，多线路可选，随时随地方便与客户沟通。一键呼出自动连拨，可配合机器人可批量拨打，实现线索筛选分类，通话录音存储，多种营销话术库。'
      },
      {
        icon:require('@images/hyj/hyjmd2-icon3.png'),
        bg:'#f5f8ff',
        img:'/images/hyj/hyjmd2-img3.png',
        tabTitle:'客户管理',
        tabConList:'客户卡片、客户标签、公海池等精细化管理，帮助了解客户需求，提供专业化产品服务，客户自动分配，未跟进自动回收，管理贷前至贷后全生命周期。若员工离职可一键交接，不带走客户资源。'
      },
      {
        icon:require('@images/hyj/hyjmd2-icon4.png'),
        img:require('@images/hyj/hyjmd2-img4.png'),
        bg:'#f5f8ff',
        tabTitle:'业务管理',
        tabConList:'客户从邀约上门、签约、进件、放款各环节清晰化，所有流程在系统中跟进记录，实时同步。营销人员对客户电话邀约上门，上门签到后自动分配谈单人，沟通完生成谈单记录，维护面谈结果。签单后做单人员，针对记录评估、放款信息等。过程可视化。'
      },
      {
        icon:require('@images/hyj/hyjmd2-icon5.png'),
        img:require('@images/hyj/hyjmd2-img5.png'),
        bg:'#f5f8ff',
        tabTitle:'数据统计',
        tabConList:'分别从通话统计、销售过程数据、销售业绩数据、谈单数据分析、做单数据分析等维度可视化分析数据。丰富的数据报表，帮助管理层全面了解公司的业务数据，科学决策。'
      },
      {
        icon:require('@images/hyj/hyjmd2-icon6.png'),
        img:require('@images/hyj/hyjmd2-img6.png'),
        bg:'#f5f8ff',
        tabTitle:'风控管理',
        tabConList:'系统需要能够根据借款人的需求和情况，提供匹配的借贷产品。根据第三方征信、大数据风控模型，客户评分等，灵活配置客户风控资料，满足个性化需求。'
      }
    ]
  },
  md3:{
    title:'助贷全流程数字化管理，助力业绩增长',
    steps:['线索获取','筛选跟进','预约到店','谈单管理','做单管理'],
    list: [{
      h4: "线索获取",
      p: '多渠道引流<br />电销邀约<br />手动录入',
      img: require('@images/hyj/hyj-md3-1.png'),
      alt:'海报裂变营销',
      span1:'获客能力提升',
      span2:'资源高效管理'
    },{
      h4: "筛选跟进",
      p: '淘客户<br />客户分配<br />客户标签',
      img:require('@images/hyj/hyj-md3-2.png'),
      alt:'筛选跟进',
      span1:'用户精准度',
      span2:'转化率提升'
    },
    {
      h4: "预约到店",
      p: '邀约上门<br />客户签到<br />接待客户',
      img: require('@images/hyj/hyj-md3-3.png'),
      alt:'预约到店',
      span1:'客户科学评估',
      span2:'高效客户沟通'
    },
    {
      h4: "谈单管理",
      p: '谈单分配<br />客户面谈<br />客户签约',
      img: require('@images/hyj/hyj-md3-4.png'),
      alt:'谈单管理',
      span1:'流程清晰合规',
      span2:'部门协作信息同步'
    },
    {
      h4: "做单管理",
      p: '进件管理<br />放款管理<br />回款管理',
      img: require('@images/hyj/hyj-md3-5.png'),
      alt:'做单管理',
      span1:'及时追踪进度',
      span2:'提高助贷业绩'
    }
  ]
  },
  md4:{
    title:'核心亮点',
    list:[
     {
      img: require('@images/hyj/icon1.png'),
      p1:'客户卡片',
      p2:'客户信息、客户资质、跟进阶段、谈单信息、签单信息、通话记录等信息清晰展示',
      bg:'#fff5eb'
     },
     {
      img:require( '@images/hyj/icon2.png'),
      p1:'签单管理',
      p2:'签单完成后，在签单管理里查看记录。包括已回款金融、待回款金额、应收费用、放款金额，全流程把控',
      bg:'#f4f2ff'
     },
     {
      img:require( '@images/hyj/icon3.png'),
      p1:'进件管理',
      p2:'对用户的借款订单进行审核，编辑进件、变更谈单人、退件、放款结算等，加快贷款进度',
      bg:'#eafaf9'
     },
     {
      img: require('@images/hyj/icon4.png'),
      p1:'回款管理',
      p2:'回款单关联应收款，回款单审核后应收款的收款进度自动更新，快速达成回款',
      bg:'#e8f6ff'
     },
     {
      img: require('@images/hyj/icon5.png'),
      p1:'做单管理',
      p2:'从客户沟通到收集资料、匹配资方、预约评估、下户调查、上报审批、资方电核、审批通过、资方签约、抵押公证全流程把控',
      bg:'#eafaf9'
     },
     {
      img: require('@images/hyj/icon6.png'),
      p1:'配合AI机器人',
      p2:'无缝对接AI机器人,批量极速触达,高意向筛选，开场白减少业务员工作，支持真人语音，提升3倍以上工作效率',
      bg:'#fff5eb'
     },
     {
      img: require('@images/hyj/icon7.png'),
      p1:'公海池管理',
      p2:'长期无跟进客户回收公海池，不同部门有多个公海池，统筹分配，可由其他销售领取，锁住客户资源',
      bg:'#eafaf9'
     },
     {
      img: require('@images/hyj/icon8.png'),
      p1:'销售漏斗',
      p2:'定制可视化的销售漏斗，销售效能管理层可随时掌握，专业数据分析科学决策，预测团队业绩',
      bg:'#f4f2ff'
     },
     {
      img: require('@images/hyj/icon9.png'),
      p1:'独立部署',
      p2:'支持独立部署，企业可以根据需求，选择在本地或云端进行系统的部署，确保数据的安全性和稳定性',
      bg:'#eafaf9'
     }
    ]
  },
  md5:{
    title:'使用慧营家系统您能收获',
    list:[
      {
        p1:'提高业务效率',
        p2:'慧营家能有效提高贷款公司业务流程自动化和数字化，从客户信息录入到预约到店、审批、放款，缩短了贷款周期，提高了销售、谈单、做单部门的工作效率。',
      },
      {
        p1:'优化客户体验',
        p2:'助贷系统通过数据分析和挖掘，了解客户需求和行为画像，为贷款公司提供精准的营销策略和专业的服务方案，不断提高客户满意度和忠诚度。',
      },
      {
        p1:'完善业务流程',
        p2:'帮助贷款公司业务流程一体化效率提高，简化和加速了贷款机构的各部门协作，从获取线索—跟进客户—预约到店—面谈签单—做单进件—放款结算。规范各个业务环节，不遗漏、不耽误任何一笔签单。',
      },
      {
        p1:'降低运营成本',
        p2:'通过自动化和智能化处理，助贷系统可以降低人力成本，同时减少因人为失误的风险和损失。新增自动化的方式处理贷款的各个流程，可以减少人力成本，从而降低运营成本。',
      },
    ]
  },
  md6:{
    title:'为什么选择我们',
    list:[
      {
        p1:'独立自主的研发能力',
        p2:'深耕行业十余年，自主研发助贷系统，服务10w+客户，提供专业解决方案',
      },
      {
        p1:'业务培训服务',
        p2:'产品售前、使用培训，提供定制化培训服务，提供销售模式、销售话术、 销售实战等业务扶持',
      },
      {
        p1:'数据隐私与安全保障',
        p2:'联系方式、身份证脱敏、数据安全加密，本地部署则可以提供更高的数据控制权和安全性',
      },
      {
        p1:'技术支持和升级',
        p2:'我们提供技术支持水平，保证慧营家系统升级频率，确保系统的稳定性和功能更新',
      },
      {
        p1:'全系统打通支持',
        p2:'全套系统支持，包括呼叫中心+CRM+找企鸟获客系统+AI机器人系统等渠道系统，数据打通高效管理',
      },
      {
        p1:'满意的客户服务',
        p2:'7x12小时顾问式客服一对一服务提供远程协作、问题处理等服务提供安装、使用、维护标准化作业指导规范',
      },
    ]
  }
}

//小F机器人
export const robot  = {
  banner: {
    bannerImg: require('@images/dxjqr/topBanner.png'),
    title: '小F智能语音机器人',
    // list: ['<span>获客-筛客-管客</span>', '<span>高效沟通精准归类</span>','<span>批量拨打配置话术</span>','<span>数据统计分析</span>'],
    list: ['获客-筛客-管客', '高效沟通精准归类','批量拨打配置话术','数据统计分析'],
    class: "bannerStyle1"
  },
  modular1: {
    title: '当下企业的困扰?',
    img: require('@images/dxjqr/beijing1.png'),
    list: [{
        p1: '<strong>01</strong>手动拨号慢',
        p2: '受情绪影响大，效果差易投诉'
      },
      {
        p1: '<strong>02</strong>不精准，离职率高',
        p2: '做重复筛选客户却不能成交，销售最终离职'
      },
      {
        p1: '<strong>04</strong>工作效率低',
        p2: '长期拨打电话易疲倦，工作效率低'
      },
      {
        p1: '<strong>03</strong>销售执行难',
        p2: '销售被拒绝太多次，执行难'
      },

    ],
    listClass: 'listStyle1', // 列表样式
    objBtn: false,
    objBtnClass: ''
  },
  modular2:{
    title:'我们的选择',
     list: [{
      p1: '高精度语义识别能力',
      childrenList:['• 采用过滤纠错、智能匹配等手段高精度识别用户意图。','• 支持自主学习，归纳行业热词库和专属知识库。','• 支持智能跳回，可自定义跳回主流程的回复话术。']
    },
    {
      p1: '真人语音话术能力',
      childrenList:['• 支持真人声音和机器人变量填槽模式。','• 支真人语音，客户自主配置，修改机器人话术和知识库。','• 海量的数据训练发音模型，合成音真实饱满。']
    },
    {
      p1: '提高销售成交能力',
      childrenList:['• 客户标签化管理<span>• 数据分析统计</span>','• AI人工无感协同<span>• 业务流程自动化</span>']
    }]
  },
  
  modular4: {
    title: '核心亮点',
    list: [{
        p1: "ASR语音识别",
        p2: '8K电话识别率达90%<br />主动降噪、回音消除<br />方言识别自动校正<br />识别模型优化，流媒体识别别矫正',
        img: require('@images/dxjqr/shine1.png'),
      },
      {
        p1: "NLP语音理解",
        p2: '100+行业话术模板与知识图谱<br/>NLP+关键词综合识别模式<br/>上下文理解多轮对话<br/>数据智能采集',
        img: require('@images/dxjqr/shine2.png'),
      },
      {
        p1: "TTS语音合成",
        p2: '多音色可选<br />支持全变量话术<br />支持真人录音<br />可定制音色模型<br />支持真人录音<br />TTS语音合成',
        img: require('@images/dxjqr/shine3.png'),
      },
      {
        p1: "RPA流程自动化",
        p2: '多音色可选<br />支持全变量话术<br />可定制音色模型<br />支持真人录音<br />',
        img: require('@images/dxjqr/shine4.png'),
      },
    ]
  },
  modular5: {
    title: '适用行业',
    list: [{
        p1: "回访邀约",
        p2: '筛选有购物需求的意向客户，拨打体验',
        img: require('@images/dxjqr/tu1.png'),
      },
      {
        p1: "智能催收",
        p2: '灵活设置催收话术，应对不同风险等级客户户',
        img: require('@images/dxjqr/tu2.png'),
      },
      {
        p1: "教培行业邀约",
        p2: '邀约参加教育培训课程体验等。',
        img: require('@images/dxjqr/tu3.png'),
      },
      {
        p1: "房产推销",
        p2: '房产销售邀约，邀请客户看盘。拨打体验',
        img: require('@images/dxjqr/tu4.png'),
      },
       {
        p1: "医疗美容",
        p2: '帮助企业筛选精准客户，深度营销，培养潜在市场客户',
        img: require('@images/dxjqr/tu5.png'),
      },
       {
        p1: "保健品",
        p2: '询问客户对产品的印象和反馈，及时跟进',
        img: require('@images/dxjqr/tu6.png'),
      },
      {
        p1: "零售行业",
        p2: '全生命周期的多话场景，人机协作，智能跟单，最大化提升顾客转化',
        img: require('@images/dxjqr/tu7.png'),
      },
      {
        p1: "政府服务",
        p2: '让机器人服务客服，人机协作，服务更高效，降低投诉',
        img: require('@images/dxjqr/tu8.png'),
      },
      {
        p1: "企业服务",
        p2: '给予用户最真实的交互体验，提高用户留资意向',
        img: require('@images/dxjqr/tu9.png'),
      },
    ]
  },

}