import Vue from 'vue'
import Router from 'vue-router'
import Home from '@views/index/index.vue'
import Index618 from '@views/index/index618.vue'

// import Cr2 from '@views/crm2/crm2.vue'
import Crm from '@views/crm2/crm2.vue'
import Outbound from '@views/outbound/outBound.vue'
import BusAgent from '@views/busAgent/busAgent.vue'
import SoftDown from '@views/softDown/softDown.vue'
import About from '@views/aboutUs/aboutUs.vue'
import News from '@views/news/news.vue'
import NewsDetail from '@views/news/newsDetails.vue'
import Product from '@views/product/product.vue'

import CallCenter from '@views/product/callCenter.vue'

import IpSwitch from '@views/product/ipSwitch.vue'
import FormMarket from '@views/product/formMarket.vue'
import HuiTalk from '@views/product/huiTalk.vue'
import HuiVisitors from '@views/product/huiVisitors.vue'
import LetterE from '@views/product/letterE.vue'
import WxMarket from '@views/product/wxMarket.vue'
import Poster from '@views/product/poster.vue'
import Card from '@views/product/card.vue'
import Sale from '@views/product/sale.vue'
import Hot from '@views/product/hot.vue'
import Show from '@views/product/show.vue'
import Customer from '@views/product/customer.vue'
import XiaoFeng from '@views/product/xiaofeng.vue'
import T5 from '@views/product/t5.vue'
import WechatMarketing from '@views/product/wechatMarketing.vue'
import Robot from '@views/product/robot.vue'
import OnlineCall from '@views/product/onlineCall.vue'
import Dx from '@views/product/Dx.vue'
import Dxjqr from '@views/product/dxjqr/dxjqr.vue'

import Qfcm from '@views/product/qfcm.vue'

import BrowseDefault from '@views/BrowseDefault.vue'


import Case from '@views/case/case.vue'   //行业案例
import Cs from '@views/case/Cs.vue'  
import Cm from '@views/case/Cm.vue'
import Dzsw from '@views/case/Dzsw.vue'
import Fdc from '@views/case/Fdc.vue'
import Hlw from '@views/case/Hlw.vue'
import Jy from '@views/case/Jy.vue'
import Jrfw from '@views/case/Jrfw.vue'
import Qc from '@views/case/Qc.vue'
import Zz from '@views/case/Zz.vue'
import Zx from '@views/case/Zx.vue'

import Zqn from '@views/zqn/zqn.vue'
import page404 from "@views/404.vue" 
//2023年01月10日 新加页面
import Excc from '@views/ExtensionPages/ExCallCenter.vue'
import ExSale from '@views/ExtensionPages/ExSale.vue'
import ExCrm from '@views/ExtensionPages/ExCrm.vue'
import ExDxxl from '@views/ExtensionPages/ExDxxl.vue'
import ExQfmp from '@views/ExtensionPages/ExQfmp.vue'
import ExWxyx from '@views/ExtensionPages/ExWxyx.vue'

//2024年1月2日
import Hyj from '@views/hyj/hyj.vue'
import { Store } from 'vuex'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  // mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/browseDefault',
      name: 'browseDefault',
      component: BrowseDefault
    },
    {
      path: '/case',
      name: 'case',
      component: Case,
      children:[
        {
          path: 'cs/:params?',
          name: 'cs',
          component: Cs,
        },
        {
          path: 'cm/:params?',
          name: 'cm',
          component: Cm,
        },
        {
          path: 'dzsw/:params?',
          name: 'dzsw',
          component: Dzsw,
        },
        {
          path: 'fdc/:params?',
          name: 'fdc',
          component: Fdc,
        },
        {
          path: 'hlw/:params?',
          name: 'hlw',
          component: Hlw,
        },
        {
          path: 'jy/:params?',
          name: 'jy',
          component: Jy,
        },
        {
          path: 'jrfw/:params?',
          name: 'jrfw',
          component: Jrfw,
        },
        {
          path: 'qc/:params?',
          name: 'qc',
          component: Qc,
        },
        {
          path: 'zz/:params?',
          name: 'zz',
          component: Zz,
        },
        {
          path: 'zx/:params?',
          name: 'zx',
          component: Zx,
        },
      ]
    },
    // {
    //   path: '/cr2/:params?',
    //   name: 'cr2',
    //   component: Cr2
    // },
    {
      path: '/crm/:params?',
      name: 'CRM',
      component: Crm
    },
    {
      path: '/hyj',
      name: 'hyj',
      component: Hyj
    },
    {
      path: '/outbound/:params?',
      name: 'Outbound',
      component: Outbound,
    },
    {
      path: '/busAgent/:params?',
      name: 'BusAgent',
      component: BusAgent
    },
    {
      path: '/softDown/:params?',
      name: 'softDown',
      component: SoftDown
    },
    {
      path: '/about/:params?',
      name: 'about',
      component: About
    },
    {
      path: '/news',
      name: 'news',
      component: News
    },
    {
      path: '/newsList.do',
      name: 'newsList.do'
    },
    {
      path: '/zqn',
      name: 'zqn',
      component: Zqn
    },
    {
      path: '/product',
      name: 'product',
      component: Product,
      children: [
        {
          path: 'callCenter/:params?',
          name: 'callCenter',
          component: CallCenter
        },
        {
          path: 'ipswitch/:params?',
          name: 'ipswitch',
          component: IpSwitch,
        },
        {
          path: 'formMarket/:params?',
          name: 'formMarket',
          component: FormMarket,
        },
        {
          path: 'qfcm/:params?',
          name: 'qfcm',
          component: Qfcm
        },
        {
          path: 'huiTalk/:params?',
          name: 'huiTalk',
          component: HuiTalk,
        }, {
          path: 'huiVisitors/:params?',
          name: 'huiVisitors',
          component: HuiVisitors
        }, {
          path: 'letterE/:params?',
          name: 'letterE',
          component: LetterE
        }, {
          path: 'wxMarket/:params?',
          name: 'wxMarket',
          component: WxMarket
        }, {
          path: 'poster/:params?',
          name: 'poster',
          component: Poster
        }, {
          path: 'card/:params?',
          name: 'card',
          component: Card
        }, {
          path: 'sale/:params?',
          name: 'sale',
          component: Sale
        }, {
          path: 'hot/:params?',
          name: 'hot',
          component: Hot
        }, {
          path: 'show/:params?',
          name: 'show',
          component: Show
        },
        {
          path: 'robot/:params?',
          name: 'robot',
          component: Robot
        },
        {
          path: 'onlineCall/:params?',
          name: 'onlineCall',
          component: OnlineCall
        },
        {
          path: 'customer/:params?',
          name: 'customer',
          component: Customer
        },
        {
          path: 'xiaofeng/:params?',
          name: 'xiaofeng',
          component: XiaoFeng
        },
        {
          path: 't5/:params?',
          name: 't5',
          component: T5
        },
        {
          path: 'wechat/:params?',
          name: 'wechat',
          component: WechatMarketing
        },
        {
          path: '/fighting618/:params?',
          name: 'fighting618',
          component: Index618
        },
        {
          path: 'dx/:params?',
          name: 'dx',
          component: Dx,
        },
        {
          path: 'dxjqr/:params?',
          name: 'dxjqr',
          component: Dxjqr,
        },
      ]
    },
    {
      path: '/exsale',
      name: 'exsale',
      component: ExSale
    },
    {
      path: '/excc',
      name: 'excc',
      component: Excc
    },
    {
      path: '/excrm',
      name: 'excrm',
      component: ExCrm
    },
    {
      path: '/exdxxl',
      name: 'exdxxl',
      component: ExDxxl
    },
    {
      path: '/exqfmp',
      name: 'exqfmp',
      component: ExQfmp
    },
    {
      path: '/exwxyx',
      name: 'exwxyx',
      component: ExWxyx
    },
    {
      path: '*',
      component: page404,
      meta:{
        footerShow:true
      }
    },
    // {
    //   path:'/:catchAll(.*)',
    //   name:'not-found',
    //   meta:{
    //     hiddenFooter:true,
    //   },
    //   component:page404,
    // },
  ]
})

// function _isMobile() {
//   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
//   return flag;
// }
// router.beforeEach((to, from, next) => {
//   console.log(to, "===to===")
//   if (_isMobile() && to.name) {
//     // {path:'/m_index.html#/'+to.name,replace:true}
//     next({
//       path: '/m_index.html#/' + to.name
//     })
//     document.location.reload();
//   } else {
//     next();
//   }
// })

router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router