<template>
  <div class="scroll">
    <swiper :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide v-for="(item,index) in swiperSlides" :key="'swiper'+index">
        <img :src="item.src" :alt="item.imgAlt" :title="item.imgTitle">
        <div class="slider-text">
          <p>{{item.text}}</p>
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <!-- <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-black" slot="button-next"></div> -->
      <!-- <div class="swiper-scrollbar"   slot="scrollbar"></div> -->
    </swiper> 
    <div class="swiper-pagination "  slot="pagination"></div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'  
export default {
  name: 'HelloWorld',
  components: {  
      swiper,  
      swiperSlide  
  },  
  data () {
    return {
       swiperOption: {  
          slidesPerView: 3,
          spaceBetween: 0,
          centeredSlides: true,
          loop:true,
          height:320,
          // width:490,
          //分页
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          //左右点击
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
        },
        swiperSlides:[
          {src:require("@img/home-swiper-img1.png"),'text':'慧营销陪伴我们将近5个年头，5年来，慧营销见证了我们的不断壮大，我们也见证了慧营销不断提升的营销价值。','imgTitle':this.$store.state.imgJson.home.img8.title,'imgAlt':this.$store.state.imgJson.home.img8.alt},
          {src:require("@img/home-swiper-img2.png"),'text':'慧营销的通信功能是我们需要的功能，我们尝试过几款同类型的软件，慧营销是让我们满意的一款。','imgTitle':this.$store.state.imgJson.home.img9.title,'imgAlt':this.$store.state.imgJson.home.img9.alt},
          {src:require("@img/home-swiper-img3.png"),'text':'慧营销功能比较强大，有很多小应用，智能名片、营销表单、慧获客，这些小应用可以免费的帮助企业获取免费资源，对我们的帮助挺多。','imgTitle':this.$store.state.imgJson.home.img10.title,'imgAlt':this.$store.state.imgJson.home.img10.alt},
          {src:require("@img/home-swiper-img4.jpg"),'text':'慧营销是我一直在用的一款软件，公司因为业务调整而取消了电销业务，而现在，我们又开始电销这一 业务，我们又选择了慧营销。','imgTitle':this.$store.state.imgJson.home.img11.title,'imgAlt':this.$store.state.imgJson.home.img11.alt},
          {src:require("@img/home-swiper-img5.jpg"),'text':'慧营销是一款值得推荐的营销软件，我的朋友推荐了我使用慧营销，我也推荐了我的很多朋友使用慧营销，业内口碑不错，值得推荐。','imgTitle':this.$store.state.imgJson.home.img7.title,'imgAlt':this.$store.state.imgJson.home.img7.alt },
        ]
    }
  },
  computed: {  
    swiper() {  
      return this.$refs.mySwiper.swiper;  
    }  
  }, 
  created(){
    this.imgJsonHome = this.$store.state.imgJson.home
  },
  mounted () {  
    //可以使用swiper这个对象去使用swiper官网中的那些方法  

  }   
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.scroll{
  height:330px;
  position: relative;
   display: flex;
   justify-content: center;
   .swiper-pagination-bullet{
      margin:0 5px; 
   }

  .swiper-container {
      width: 100%;
      height:310px;
    }
    .swiper-slide {
      // width:490px;  
      height:310px;
      text-align: center;
      font-size: 14px;
      background: #fff;
      img{
        display:block;
        width:100%;
        height:100%;
      }
    }
    .swiper-slide:not(.swiper-slide-active){
        transform: scale(0.8);
    } 
    .slider-text{
      display: none;
      position: absolute;
      padding:10px 10px;
      width:400px;
      height:106px;
      background:rgba(34,153,238,0.7);
      color:#fff;
      line-height:26px;
      font-size:16px;
      box-sizing: border-box; 
      z-index:100;
      bottom:0; 
      left:0;
      text-align:left; 
      overflow: hidden;
    }
  .swiper-slide-active .slider-text{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}  
  .scroll .swiper-pagination{
      bottom:-10px; 
      left:0;
      width:100%;
      text-align: center;
  }
</style>