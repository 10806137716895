<!--  -->
<template>
  <div class='home-container'>
    <BannerSwiper></BannerSwiper>
    <!-- <Banner :bgImg="bannerImg" bgcolor="#cde1f5">
        <div class="banner-text">
          <h4>赋能中小企业，打造一站式综合营销服务平台</h4>
          <ul>
            <li>• 慧营销基于SAAS的CRM营销服务平台</li>
            <li>• 为企业打通客户微信、钉钉、qq、旺旺社交链</li>
            <li>• 运用多种形式的营销推广工具</li>
            <li>• 智能驱动每一个营销环节</li>
          </ul>
        </div>
        <apply-dialog><button class="applayBtnRed" style="margin: 20px 0 0;" @click = "$store.commit('CHANGE_MODAL_TRUE')">免费试用</button></apply-dialog>
    </Banner> -->
    <div>
      <tb-module :moduleImg="moduleImg1" :lineW="lineW1" :moduleText="moduleText1" :title="title1" :imgTitle="imgJsonHome.img1.title" :imgAlt="imgJsonHome.img1.alt"></tb-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module :moduleImg="moduleImg2" :lineW="lineW2" :moduleText="moduleText2" :title="title2" :imgTitle="imgJsonHome.img2.title" :imgAlt="imgJsonHome.img2.alt"></tb-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <lr-module :moduleImg="moduleImg3" :lineW="lineW3" :title="title3" :imgTitle="imgJsonHome.img3.title" :imgAlt="imgJsonHome.img3.alt">
        <ul class="module3">
          <li>
            <h5>■ 营销计划贯穿全程</h5>
            <p>业绩目标自下而上自动量化、逐层分解<br />精细化管理销售计划，细化销售任务</p>
          </li>
          <li>
            <h5>■ 营销过程动态跟进</h5>
            <p>透明管理效率提升，执行进度实时跟踪</p>
          </li>
          <li>
            <h5>■ 营销数据支撑决策</h5>
            <p>执行数据自动上报汇总，依托数据调整业绩目标<br />多维度数据体现销售过程及销售业绩，帮助开展<br />日程管理工作</p>
          </li>
          <!-- <li>
            <h5>■ 团队文化激发销售热情</h5>
            <p>打赏、徽章、弹幕、积分、荣誉墙</p>
          </li> -->
        </ul>
      </lr-module>     
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <lr-module :moduleImg="moduleImg4" :lineW="lineW4" :title="title4" flexDrect="row-reverse" mgTop="75px" :imgTitle="imgJsonHome.img4.title" :imgAlt="imgJsonHome.img4.alt">
        <ul class="module4">
          <li>
           ■ 记忆导入
           <p>智能匹配</p>
          </li>
          <li>
          ■ 多模式多策略
           <p>意向开发工具</p>
          </li>
          <li>
           ■ 屏幕取号
           <p>自动对比</p>
          </li>
          <li>
          ■ 网站客户
           <p>自动发现</p>
          </li>
          <li>
          ■ 整合电话/短信
           <p> 邮件/QQ/微信/旺旺</p>
          </li>
          <li>
          ■ 秒级筛客
           <p>一键拨号</p>
          </li>
        </ul>
      </lr-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <lr-module :moduleImg="moduleImg5" :lineW="lineW5" :title="title5" mgTop="30px" :imgTitle="imgJsonHome.img5.title" :imgAlt="imgJsonHome.img5.alt">
        <ul class="module5">
          <li>
            <p>■ 为企业提供基于固话、手机的软硬一体化的通信解决方案</p>
          </li>
          <li>
            <p>■ 支持移动、联通、电信三大运营商</p>
          </li>
          <li>
            <p>■ 支持4G四卡双通道，0秒切换</p>
          </li>
          <li>
            <p>■ 提供开放的API接口，快捷方便接入第三方软件</p>
          </li>
        </ul>
      </lr-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>

      <lr-module :moduleImg="moduleImg9" :lineW="lineW6" :title="title9" flexDrect="row-reverse" mgTop="75px" :imgTitle="imgJsonHome.img4.title" :imgAlt="imgJsonHome.img4.alt">
        <ul class="module5" style="line-height:50px;">
          <li>
           ■ 连接微信,QQ,旺旺,实现多渠道沟通
           <!-- <p>多渠道沟通</p> -->
          </li>
          <li>
          ■ 语音通话可存储，聊天记录可追溯
          </li>
          <li>
           ■ 数据安全有保障，支持私有云，公共云混合部署
           <!-- <p>云混合部署</p> -->
          </li>
          <li>
          ■ 深度整合个人微信、企业微信，提升企业营销效率
          </li>
        </ul>
      </lr-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>


        
      <tb-module :moduleImg="moduleImg6" :lineW="lineW2" :moduleText="moduleText6" :title="title6" :imgTitle="imgJsonHome.img6.title" :imgAlt="imgJsonHome.img6.alt"></tb-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>
      <tb-module  :lineW="lineW1" :moduleText="moduleText7" :title="title7"></tb-module>

      <div style="width:1200px;margin:40px auto;">
        <Swiper></Swiper>
      </div>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>



      <tb-module :moduleImg="moduleImg8" :lineW="lineW1" :moduleText="moduleText8" :title="title8" :imgTitle="imgJsonHome.img12.title" :imgAlt="imgJsonHome.img12.alt"></tb-module>
      <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button>


      <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
      <news-problem></news-problem>
    </div>
  </div>
</template>

<script>
import Banner from '@/modudel/Banner.vue'
import TbModule from '@/modudel/TbModule.vue'
import LrModule from '@/modudel/LrModule.vue'
import newsProblem from '@/modudel/newsOrProblem.vue'
import Swiper from './components/Sweiper.vue'
import BannerSwiper from './components/BannerSwiper.vue'
import {mapState,mapActions } from 'vuex'
export default {
  name:'home',
  components: {
    Banner,
    TbModule,
    LrModule,
    newsProblem,
    Swiper,
    BannerSwiper
  },
  data() {
     return {
        bannerImg:require('../../assets/img/homeBanner.png'),
        moduleImg1:require('../../assets/img/home-module.png'),
        moduleImg2:require('../../assets/img/home-module2.png'),
        moduleImg3:require('../../assets/img/home-module3.png'),
        moduleImg4:require('../../assets/img/home-module4.png'),
        moduleImg5:require('../../assets/img/home-module5.jpg'),
        moduleImg6:require('../../assets/img/home-module6.png'),
        moduleImg7:require('../../assets/img/home-module6.png'),
        moduleImg8:require('../../assets/img/home-module8.png'),
        moduleImg9:require('../../assets/img/home-module9.jpg'),
        title1:'凝聚核心价值',
        title2:'多渠道获客引流',
        title3:'高效的营销管理',
        title4:'促进营销效率翻倍',
        title5:'企业通信畅达无忧',
        title6:'提升服务满意度',
        title7:'用户的心声',
        title8:'100000+企业',
        title9:'SCRM,企业沟通更便捷',
        lineW1:"200px",
        lineW2:"150px",
        lineW3:'50px',
        lineW4:'30px',
        lineW5:'20px',
        lineW6:'5px',
        moduleText1:'慧营销始终以为"中小企业提供高价值低成本的营销服务"为己任，不断在企业营销服务的道路上开拓创新，形成了企业营销推广、获客、开发、管理、运营、维护的全流程企业营销服务体系，从而更好的赋能企业创造价值',
        moduleText2:'有效精准的获客用找企鸟，主流平台的线索开发及转化使用慧获客，企蜂名片打造私域流量建设，企业微信专属活码获客分流',
        moduleText6:'慧营销的价值是为中小企业提供优质的营销服务，您的满意是我们看重的、关心的。为了赢得您对慧营销的认可，慧营销从多方面优化售后流程，提升服务人员的技能水平和服务水准。',
        moduleText8:'覆盖互联网金融、电商、汽车、在线教育、企业服务、医疗等众多领域',
        moduleText7:"在慧营销努力为每一个客户做好服务的过程中，始终离不开广大用户的支持与理解，同时，也获得了用户的肯定与掌声。",
        imgJsonHome:this.$store.state.imgJson.home
     };
  },
  // metaInfo () {
  //   return {
  //     title:this.headParams.name,
  //     meta: [{                 // set meta
  //     name: 'keyWords',
  //     content: this.headParams.keyword,
  //   },{                 // set meta
  //     name: 'description',
  //     content: this.headParams.desc,
  //   }]
  //   }
  // },
  computed: mapState([
    'headParams'
  ]),
  watch: {},
  created() {
    this.GetSystemData();
  },
  mounted() {

  },
  //方法集合
  methods: {
    ...mapActions([
      'GetSystemData', // 将 `this.increment()` 映射为 `this.$store.dispatch('increment')`
    ]),
  },
  updated() {},
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
 
  .module3{
    width:350px;
    margin-top:50px;
    li{
      margin-bottom:20px;
    }
    h5{
      font-size:18px;
      color:#333;
      font-weight: bold;
    }
    p{
      font-size:16px;
      color:#333;
      line-height:30px;
    }
  }
  .module4{
    display:flex;
    flex-wrap:wrap;
    margin:45px auto;
    width:430px; 
    li{
      width:33.33%;
      margin-bottom:25px;
      line-height:30px;
      text-align:center;
    }
  }

  .module5{
    margin-top:40px;
    font-size:16px;
    color:#333;
    line-height:35px;
  }
</style>