<template>
  <div class="indexBannerScroll">
    <!-- <swiper :options="swiperOption" style="575px"> -->
      <!-- <swiper-slide>
        <div class="indexBanner6">
          <div class="container-1200px"><a href="/news.do?id=220321gcl0" target="_blank">点击详情</a></div>
        </div>    
      </swiper-slide> -->
      <!-- <swiper-slide>
        <div class="indexBanner1">
          <div style="margin:0 auto;width:1200px;">
            <div class="banner-text">
              <h4>赋能中小企业，打造一站式综合营销服务平台</h4>
              <ul>
                <li>• 慧营销基于SAAS的CRM营销服务平台</li>
                <li>• 为企业打通客户微信、钉钉、qq、旺旺社交链</li>
                <li>• 运用多种形式的营销推广工具</li>
                <li>• 智能驱动每一个营销环节</li>
              </ul>
            </div>
            <button
              class="applayBtnRed"
              style="margin: 20px 0 0;"
              @click="$store.commit('CHANGE_MODAL_TRUE')"
            >免费试用</button>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide v-for="(item,index) in imgArr" :key="index">
        <div class="indexBannerAll" :style="{'background-image':'url('+item+')'}"></div>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination-a" slot="pagination"></div> -->
    <Carousel >
      <Carousel-item>
        <div class="indexBanner1">
          <div style="margin:0 auto;width:1200px;">
            <div class="banner-text">
              <h4>赋能中小企业，打造一站式综合营销服务平台</h4>
              <ul>
                <li>• 慧营销基于SAAS的CRM营销服务平台</li>
                <li>• 为企业打通客户微信、qq、旺旺社交链</li>
                <li>• 运用多种形式的营销推广工具</li>
                <li>• 智能驱动每一个营销环节</li>
              </ul>
            </div>
            <button
              class="applayBtnRed"
              style="margin: 20px 0 0;"
              @click="$store.commit('CHANGE_MODAL_TRUE')"
            >免费试用</button>
          </div>
        </div>
      </Carousel-item>
      <Carousel-item v-for="(item,index) in imgArr" :key="index">
        <div class="indexBannerAll" :style="{'background-image':'url('+item+')'}"></div>
      </Carousel-item>
    </Carousel>   
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Carousel,Button,CarouselItem} from 'iview'
import ApplyDialog from "@/modudel/ApplyDialog.vue";
export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
    ApplyDialog,
    Carousel,
    Button,
    CarouselItem
  },
  props: {},
  data() {
    return {
      value1:0,
      swiperOption: {
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: ".swiper-pagination-a",
          clickable: true
        },
      
      },
        imgArr:[
        require('../../../assets/img/index-banner3.png'),require('../../../assets/img/index-banner4.png'),require('../../../assets/img/index-banner5.png')
        ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    bannerLinkTo() {
      // this.$router.push("/fighting618")
      location.href = location.origin + "/news.do?id=200728t271"
    }
  }
};
</script>

<style lang="scss" scoped>
.indexBannerAll{
   width:100%;
   height: 575px;
   background-position:center top;
   background-repeat: no-repeat;
}
.indexBanner6 {
  height: 575px;
  background: url("../../../assets/img/index-banner6.png") no-repeat center top;
  .container-1200px{
    padding-top:363px;
  }
  a{
    display: block;
    
    width:180px;
    height:51px;
    background:#fec244;
    border-radius: 30px;
    color:#fff;
    text-align: center;
    line-height: 51px;
    font-size:20px;
  }
}
.indexBanner2 {
  height: 575px;
  background: url("../../../assets/img/index-banner2.jpg") no-repeat center top;
}
.indexBanner1 {
  height: 575px;
  background: url("../../../assets/img/homeBanner.png") no-repeat center top;
}
.banner-text {
  padding-top: 180px;
  h4 {
    margin-bottom: 42px;
    font-size: 30px;
    color: #333;
    line-height: normal;
  }
  li {
    height: 40px;
    font-size: 18px;
    color: #333;
  }
}

.indexBanner2 .banner-text {
  color: #fff;
  h4 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
  }
  h5 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  ul {
    li {
      color: #fff;
    }
  }
}
</style>
<style lang="scss">
.indexBannerScroll {
  position: relative;
  .swiper-pagination-a {
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.demo-carousel{
background:blue;

}

</style>
<style >
  .indexBannerScroll .ivu-carousel-dots li button{
  background:#fff;
  }   
</style>
